<template>
  <div class="dashboard-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button class="el-icon-edit" type="primary"    :disabled='activeData.length!==0' size="small" @click="add"
            >设置联系方式</el-button
          >
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="40%"
      @close="btnCancel"
    >
      <el-form
        :model="phoneCodeDto"
        :rules="rules"
        ref="perForm"
        label-width="200px"
      >
        <el-form-item label="手机" prop="phone">
          <el-input v-model="phoneCodeDto.phone" style="width: 60%"></el-input>
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button @click="btnCancel" size="small">取 消</el-button>
        <el-button type="primary"  size="small" @click="enter">确 定</el-button>
        </el-col>
      </el-row>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="btnCancel" size="small">取 消</el-button>
        <el-button type="primary"  size="small" @click="enter">确 定</el-button>
      </span> -->
    </el-dialog>
    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="activeData"
        border
        style="width: 50%"
        ref="activeData"
        class="emp_table"
        v-if="activeData"
      >
        <el-table-column
          align="center"
          prop="phone"
          label="客服联系电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column align="center" label="操作" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getPhone, addphone, updatePhone } from '../../request/http'
export default {
  data() {
    return {
      addorredit: false,
      title: '',
      // 表格配置

      // 表格数据
      activeData: [],
      showDialog: false,
      phoneCodeDto: {
        phone: ''
      },

      rules: {
        phone: [
         {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }],
      }
    }
  },
  created() {
    this.show()
    console.log(this.activeData.length)
  },
  methods: {
    updateSchoolInfo(row) {
      this.title = '编辑联系方式'
      console.log(row)
      this.showDialog = true

      this.phoneCodeDto = {
        phone: row.phone
      }
    },
    btnCancel() {
      this.phoneCodeDto = {}
      this.$refs.perForm.resetFields()
      this.showDialog = false
    },

    add() {
      this.title = '设置联系方式'
      this.showDialog = true

    },
    show() {
      getPhone().then((res) => {
       

        if (res.data.data) {
          this.activeData = [
            {
              phone: res.data.data
            }
          ]
        } else {
          this.activeData = []
        }
      })
      console.log(this.activeData)
    },

    enter() {
      
      if (this.title == '设置联系方式') {
      
        addphone(this.phoneCodeDto).then((res) => {
          if (res.code == 0)
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          this.showDialog = false
          this.show()
        })
      } else if (this.title == '编辑联系方式') {
        updatePhone(this.phoneCodeDto).then((res) => {
          if (res.code == 0)
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
          this.showDialog = false
          this.show()
        })
      }
    }
  }
}
</script>

<style >
</style>